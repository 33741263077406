import React, {useEffect, useState, useContext} from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import {useHistory} from "react-router-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import route from "./routes";
import {useSelector, useDispatch} from "react-redux";
import {get_all_users, get_all_groups, get_all_permisions} from '../redux/users';
import {getAllProvinces} from '../redux/provinces';
import {getAllCities} from '../redux/cities';
import {getAllStores} from '../redux/stores';
//import {getAllAssistControl} from '../redux/assist_control';
import {getAllTaskTypes} from '../redux/task_types';
//import {getAllSchedules} from '../redux/schedules';
import {getAllTaskList} from '../redux/task';
import {getAllNotifications} from '../redux/notifications';
import {getAllObservation} from '../redux/observation';
import {getAllZones} from '../redux/zones';
import {
    getAllTaskScheduled,
    getAllTaskScheduledUser,
    GET_REPORT,
    save_notification,
    check_export_status,
    clear_task_scheduled,
    getAllActivities
} from '../redux/task_scheduled';
import {SocketContext} from "../config/ws-client";
import {
    TYPE_UPDATE_MESSAGE,
    TYPE_REPORT_GENERATE,
    TYPE_CREATE_MESSAGE,
    TYPE_CREATE_NOTIFICATIONS
} from '../config/ws-client'
import {toast} from "react-toastify";
import {getAllSkipTask} from "../redux/skip_task";


const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _data_login = useSelector((state) => state.users_module.login);
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [permisos, setPermissions] = useState([]);
    const {socket} = useContext(SocketContext);
    const _get_notification = useSelector((state) => state.task_scheduled_module._get_notification);
    const [token, setToken] = useState("")

    const audio = new Audio("/base/assets/audio/audio.mp3");

    var day = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }
    var date = year + '-' + month + '-' + day;

    useEffect(() => {
        if (_data_login.status >= 200) {
            setToken(_data_login.data.token);
            dispatch(get_all_users(_data_login.data.token));
            dispatch(get_all_groups(_data_login.data.token));
            dispatch(get_all_permisions(_data_login.data.token));
            dispatch(getAllProvinces(_data_login.data.token));
            dispatch(getAllCities(_data_login.data.token));
            dispatch(getAllZones(_data_login.data.token));
            dispatch(getAllStores(_data_login.data.token));
            //dispatch(getAllAssistControl(_data_login.data.token));
            dispatch(getAllTaskTypes(_data_login.data.token));
            //dispatch(getAllSchedules(_data_login.data.token));
            dispatch(getAllTaskList(_data_login.data.token)); 
            dispatch(getAllTaskScheduled(_data_login.data.token, 1));
            dispatch(getAllTaskScheduledUser(_data_login.data.token, _data_login.data.user, date));
            dispatch(getAllActivities(_data_login.data.token));
            dispatch(getAllObservation(_data_login.data.token)); 
            dispatch(getAllSkipTask(_data_login.data.token)); 
            dispatch(getAllNotifications(_data_login.data.token, _data_login.data.user)); 
        } else {
            history.push("/");
        }

        if (JSON.stringify(_session_data.data) !== "{}") {
            if (_session_data.data.grupo_permiso) {
                if (_session_data.data.grupo_permiso.permisos) {
                    setPermissions(_session_data.data.grupo_permiso.permisos);
                }
            }
        }

    }, [dispatch, _data_login, _session_data, history, date]);


    useEffect(() => {
        handleMessageWs();
        // eslint-disable-next-line
    }, [socket])

    // const sleep = (ms) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }


    const handleMessageWs = () => {
        if (socket) {
            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === TYPE_UPDATE_MESSAGE) {
                    update_message(data, socket)
                    return;
                }

                if (data.type === TYPE_REPORT_GENERATE) {
                    report_generate(data, socket);
                }

                if (data.type === TYPE_CREATE_MESSAGE) {
                    create_message(data, socket)
                }

                if (data.type === TYPE_CREATE_NOTIFICATIONS) {
                    notifications_all(data, socket)
                }

            }
        }
    }


    const update_message = (data, socket) => {
        toast.success(data.message);

        const notification = {
            title: data.title,
            message: data.message,
            result: null,
            id: data.id,
            read: false,
            type: TYPE_UPDATE_MESSAGE,
            show: true,
            date: data.date
        }
        let n = _get_notification.data ?? [];
        n.push(notification)
        dispatch(save_notification(n));
        dispatch(getAllTaskScheduled(token, 1));
        audio.play();
    }

    const create_message = (data, socket) => {
        console.log('Crear')
        dispatch(getAllTaskScheduled(token, 1));
        dispatch(getAllActivities(token));
        // dispatch(getAllTaskScheduledUser(token, _data_login.data.user, date));
    }

    const notifications_all = (data, socket) => {
        dispatch(getAllNotifications(token, _data_login.data.user));
        audio.play();
    }

    let timer = null;

    const report_generate = (data, socket) => {
        timer = setInterval(() => {
            check_export_status(token, data.id_task).then(e => {
                let datos = e.data;
                if (e.status === 200) {
                    dispatch(clear_task_scheduled(GET_REPORT))
                    const report = {
                        title: "Reporte Generado ",
                        message: datos.result.nombre,
                        result: datos.result,
                        id: datos.id_task,
                        read: false,
                        date: datos.date,
                        type: TYPE_REPORT_GENERATE,
                        show: true

                    }
                    let n = _get_notification.data ?? [];
                    n.push(report)
                    dispatch(save_notification(n));

                    audio.play();
                    clearInterval(timer)
                    timer = null;

                }
            }).catch(e => {
                clearInterval(timer)
                timer = null;
            })
        }, 5000)
    }

    return (
        <Router>
            <div className="header-navbar-shadow"/>
            <Navbar/>
            <Sidebar/>
            <Switch>
                {/* eslint-disable-next-line*/}
                {route.map((item, index) => {
                    if (!item.meta.requiresAuth) {
                        return (
                            <Route path={item.path} exact={item.exact}>
                                {<item.component {...permisos} />}
                            </Route>
                        );
                    }

                    if (permisos.filter(x => item.meta.permission_required.includes(x.codigo)).length > 0) {
                        return (
                            <Route path={item.path} exact={item.exact}>
                                {<item.component {...permisos} />}
                            </Route>
                        );
                    }
                })}
            </Switch>
        </Router>
    );
};
export default Index;
