import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { URL_GEOLOCALIZATION } from '../../../config';
import axios from "axios";
import LoadingSpinner from "../../../components/loading-spinner";
// import Modal from "../../../components/modal";
// import {IconButton} from "@material-ui/core";
// import Grid from "@material-ui/core/Grid";
// import Box from "@material-ui/core/Box";
// import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded";
import './fileMobile.css';
import { toast } from 'react-toastify';
import { clear_assist_control, searchAssistControl, UPDATE_ASSIST_CONTROL, updateAssistControl } from '../../../redux/assist_control';
import Footer from "../../../components/footer";


function inittDate() {
    try {
        let date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        var value = year + '-' + month + '-' + day;
        return value;
    } catch (e) {
        return null;
    }
}

const CheckOut = (props) => {
    const dispatch = useDispatch();

    const {token, user} = useSelector((state) => state.users_module.login.data) || null;

    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [date, setDate] = useState(inittDate());
    const [adress, setAdrees] = useState('');
    const [time, setTime] = useState('18:00:00');
    // const [namePhoto, setNamePhoto] = useState('');
    // const [uriPhoto, setUriPhoto] = useState('');
    const [type, setType] = useState('tienda');
    const [marking, setMarking] = useState(false);
    // const [file, setFile] = useState(null);
    // const [fileImage, setFileImage] = useState(null);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    // const [modalCamera, setModalCamera] = useState(false);
    const [store, setStore] = useState('');
    const [idAssistControl, setIdAssistControl] = useState(0);

    useEffect(() => {
        assist_control();
        // eslint-disable-next-line
      }, []);

    // function handleClear() {
    //     setFileImage(null);
    //     setFile(null);
    // }

    // const web = navigator.userAgent.toUpperCase();
    // let mobile = web.indexOf("MOBILE");
    // let android = web.indexOf("ANDROID");
    // let iphone = web.indexOf("IPHONE");

    function getGeolocation(id) {
        // setIsLoading(true);
        navigator.geolocation.getCurrentPosition(
            function (position) {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                geodecoder(position.coords.latitude, position.coords.longitude)
            },
            (err) => {console.log(err)},
            {enableHighAccuracy: false, timeout: 27000, maximumAge: 30000});
    }

    function geodecoder(latitude, longitude) {
        var config = {
          method: 'get',
          url:
            URL_GEOLOCALIZATION +
            '?format=jsonv2&lat=' +
            latitude +
            '&lon=' +
            longitude,
          headers: {},
        };
        axios(config)
          .then(function (response) {
              setAdrees(response.data.display_name);
          })
          .catch(function (error) {
            console.log(error);
        });
    }

    function clear_store() {
        setTime('18:00:00');
        setAdrees('');
        // setNamePhoto('');
        // setUriPhoto('');
        setLatitude('');
        setLongitude('');
        setType('tienda');
        setStore([]);
        setMarking(false);
      }

    async function assist_control() {
        let params = "?usuario=" + user + '&&fecha=' + date + '&&codigo_tipo=' + type;
        const response = await searchAssistControl(token, params);
        if (response.data.cantidad > 0) {
            let cant = parseInt(response.data.cantidad);
            if (cant > 0) {
                cant = cant - 1;
            } else {
                cant = 0;
            }
            let data = response.data.datos[cant];
            setIdAssistControl(data.id);
            setStore(data.tienda);
            if (data.ubicacion_final !== '') {
                setTime(data.ubicacion_final.hora);
                let latitude = data.ubicacion_final.latitud;
                let longitude = data.ubicacion_final.longitud;
                setLatitude(latitude);
                setLongitude(longitude);
                geodecoder(latitude, longitude);
                // let nameImage = URLAPI + data.ubicacion_final.imagenes[0].nombre;
                // let image = URLAPI + data.ubicacion_final.imagenes[0].imagen;
                // setNamePhoto(nameImage);
                // setUriPhoto(image);
                setMarking(true);
            }
        } else {
            clear_store();
        }
    }

    // const handleSubmit = async () => {
    //     setNamePhoto(file.name);
    //     setUriPhoto(fileImage);
    //     setModalCamera(false);
    // }

    // const handleCapture = (target) => {
    //     if (target.files) {
    //         if (target.files.length !== 0) {
    //             const file = target.files[0];
    //             setFile(file);
    //             const newUrl = URL.createObjectURL(file);
    //             setFileImage(newUrl);
    //         }
    //     }
    // };

    const _update_assist_control = useSelector(
        (state) => state.assist_control_module._update_assist_control
    );

    useEffect(() => {
        if (_update_assist_control) {
            if (_update_assist_control.status === 200) {
                assist_control();
                dispatch(clear_assist_control(UPDATE_ASSIST_CONTROL));
                setMarking(true);
                //assist_control();
            } else if (_update_assist_control.status !== 0) {
                dispatch(clear_assist_control(UPDATE_ASSIST_CONTROL));
                toast.error(_update_assist_control.message, {position: toast.POSITION.TOP_RIGHT});
            }
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [_update_assist_control, dispatch]);

    // const comprimirImagen = (imagenComoArchivo, porcentajeCalidad) => {
    //     return new Promise((resolve, reject) => {
    //         const $canvas = document.createElement("canvas");
    //         const imagen = new Image();
    //         imagen.onload = () => {
    //             $canvas.width = imagen.width;
    //             $canvas.height = imagen.height;
    //             $canvas.getContext("2d").drawImage(imagen, 0, 0);
    //             $canvas.toBlob(
    //                 (blob) => {
    //                     if (blob === null) {
    //                         return reject(blob);
    //                     } else {
    //                         resolve(blob);
    //                     }
    //                 },
    //                 "image/jpeg",
    //                 porcentajeCalidad / 100,
    //             );
    //         };
    //         imagen.src = URL.createObjectURL(imagenComoArchivo);
    //     });
    // };

    async function update_marking() {
        setIsLoading(true);
        // const blob = await comprimirImagen(file, 40);
        // var fileConvert = new File([blob], file.name);
        let data = {};
        data['usuario'] = user;
        data['latitud'] = latitude;
        data['longitud'] = longitude;
        // data['imagen'] = fileConvert;
        dispatch(updateAssistControl(token, idAssistControl, data));

    }

    return (
        <>
            <div className="app-content content">
                {isLoading && <LoadingSpinner/>}
                <div className="content-wrapper">
                    <div className="content-body">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className='text-center'>
                                        <p className='texto_24'>Salida de</p>
                                        <p className='texto_30'>Tienda</p>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <div className='mb-1'>
                                                <img className='ml-2 img_1'
                                                    src={window.location.origin + "/base/app-assets/images/icon/salida.png"}
                                                    alt="img"
                                                />
                                                <span className="ml-1 texto_24">Salida</span>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-1">
                                            <div className="form-group ml-2">
                                                <label>Tienda</label>
                                                <p className='texto_24'>{store}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 text-center mb-1'>
                                            <span className='border_clock texto_48'>{time}</span>
                                        </div>
                                        <div className='col-12 mt-1'>
                                            {/*{namePhoto ? (*/}
                                            {/*    <img className='ml-2 img_1'*/}
                                            {/*        src={uriPhoto}*/}
                                            {/*        alt="img"*/}
                                            {/*    />*/}
                                            {/*) : (*/}
                                            {/*    <img className='ml-2 img_2'*/}
                                            {/*        src={window.location.origin + "/base/app-assets/images/icon/image.png"}*/}
                                            {/*        alt="img"*/}
                                            {/*    />*/}
                                            {/*)}*/}
                                            <img className='ml-2'
                                                src={window.location.origin + "/base/app-assets/images/icon/position.png"}
                                                alt="img"
                                                height="30"
                                                width="30"/>
                                            <span className='texto_16'>{adress}</span>
                                        </div>
                                        <div className='col-12 text-center mt-2'>
                                            {(!marking && idAssistControl !== 0) && (
                                                (adress) ?
                                                    <>
                                                        {/*eslint-disable-next-line*/}
                                                        <a href="#" onClick={() => {
                                                            update_marking();
                                                        }} className="btn text-bold-700 btn_2">
                                                            Confirmar
                                                        </a>
                                                    </>
                                                    : 
                                                    <>
                                                        {/*eslint-disable-next-line*/}
                                                        <a href="#" onClick={() => {
                                                            setType('tienda');
                                                            getGeolocation(1);
                                                            // handleClear();
                                                            // setModalCamera(true);
                                                        }} className="btn text-bold-700 btn_1">
                                                            Marcar
                                                        </a>
                                                    </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<Modal className="modal-main" show={modalCamera} style={{width: "80%"}}>*/}
                {/*    {isLoading && <LoadingSpinner/>}*/}
                {/*    <div className="modal-header">*/}
                {/*    <p style={{color: 'transparent'}}>.</p>*/}
                {/*        <button type="button" style={{marginRight: -24}}*/}
                {/*                className="btn btn-calendar badge-circle" onClick={() => {*/}
                {/*            handleClear();*/}
                {/*            setModalCamera(false);*/}
                {/*            setUriPhoto('');*/}
                {/*            setNamePhoto('');*/}
                {/*        }}> X*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*    {(mobile === -1 && android === -1 && iphone === -1) ? (*/}
                {/*        <div className="container text-center">*/}
                {/*            <h5 className='text-bold-400 texto_24'>Use un teléfono para continuar</h5>*/}
                {/*        </div> */}
                {/*    ) : (*/}
                {/*        <div className="root">*/}
                {/*            <Grid container>*/}
                {/*                <Grid item xs={12}>*/}
                {/*                    <div className={!file && 'btnPhoto'}>*/}
                {/*                        <h5 className='text-bold-400 texto_24 mt-1'>Presione el botón para tomar la fotografía</h5>*/}
                {/*                        {file && (*/}
                {/*                            <>*/}
                {/*                                <Box*/}
                {/*                                    display="flex"*/}
                {/*                                    justifyContent="center"*/}
                {/*                                    border={1}*/}
                {/*                                    className="imgBox"*/}
                {/*                                >*/}
                {/*                                    <img src={fileImage} alt={"snap"} className="img" width="80%" height="80%"/>*/}
                {/*                                </Box>*/}
                {/*                            </>*/}
                {/*                        )}*/}
                {/*                        <input*/}
                {/*                            accept="image/*"*/}
                {/*                            className="input"*/}
                {/*                            id="icon-button-file"*/}
                {/*                            type="file"*/}
                {/*                            capture="environment"*/}
                {/*                            onChange={(e) => handleCapture(e.target)}*/}
                {/*                        />*/}
                {/*                        <label htmlFor="icon-button-file">*/}
                {/*                            <IconButton*/}
                {/*                                color="primary"*/}
                {/*                                aria-label="Tomar fotografía"*/}
                {/*                                component="span"*/}
                {/*                            >*/}
                {/*                                <PhotoCameraRoundedIcon fontSize="large" color="primary"/>*/}
                {/*                            </IconButton>*/}
                {/*                        </label>*/}
                {/*                        {*/}
                {/*                            fileImage && (*/}
                {/*                                <div className="text-center mb-1">*/}
                {/*                                    /!*eslint-disable-next-line *!/*/}
                {/*                                    <a href="#" onClick={handleSubmit} className="btn text-bold-700 mt-1 btn_1">*/}
                {/*                                        Continuar*/}
                {/*                                    </a>*/}
                {/*                                </div>*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    </div>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</Modal>*/}
            </div>
            <div className="app-content content">
                <Footer/>
            </div>
        </>
    )
}

export default CheckOut;