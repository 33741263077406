import Users from "../pages/admin/users/index";
import CreateUser from "../pages/admin/users/create";
import UpdateUser from "../pages/admin/users/update";
import GroupList from '../pages/admin/groups/index';
import CreateGroup from '../pages/admin/groups/create';
import UpdateGroup from '../pages/admin/groups/update';
import ProvincesList from '../pages/admin/provinces/index'
import CreateProvince from "../pages/admin/provinces/create";
import UpdateProvince from "../pages/admin/provinces/update";
import CitiesList from '../pages/admin/cities/index'
import CreateCity from "../pages/admin/cities/create";
import UpdateCity from "../pages/admin/cities/update";
import StoresList from '../pages/admin/stores/index'
import CreateStore from "../pages/admin/stores/create";
import UpdateStore from "../pages/admin/stores/update";
// import AssistControlList from "../pages/admin/assist_control/index";
// import DetailAssistControl from "../pages/admin/assist_control/detail";
import TaskTypesList from "../pages/admin/task_types/index";
import CreateTaskTypes from "../pages/admin/task_types/create";
import UpdateTaskTypes from "../pages/admin/task_types/update";
//import SchedulesList from "../pages/admin/schedules/index";
//import CreateSchedules from "../pages/admin/schedules/create";
//import UpdateSchedules from "../pages/admin/schedules/update";
import TaskList from "../pages/admin/task/index";
import UpdateTask from "../pages/admin/task/update";
import TaskScheduledList from "../pages/admin/task_scheduled/index";
import TaskScheduledListUser from "../pages/admin/task_scheduled/task_scheduled";
import ActivitiesList from "../pages/admin/task_scheduled/activities";
import DetailActivities from "../pages/admin/task_scheduled/detail_activities";
import ActivitiesMonthly from "../pages/admin/task_scheduled/activities_monthly";
import ActivitiesAgenda from "../pages/admin/task_scheduled/activities_agenda";
import TaskResources from "../pages/admin/task_scheduled/task_resources";
import NotificationsList from "../pages/admin/notifications/index";
import Marking from "./admin/marking";
import CheckIn from "./admin/stores/check_in";
import CheckOut from "./admin/stores/check_out";
import Init from "./admin/init"
import Observations from "../pages/admin/observations";
import SkipTask from "../pages/admin/skip_task";
import ZonesList from '../pages/admin/zones/index'
import CreateZone from "../pages/admin/zones/create";
import UpdateZone from "../pages/admin/zones/update";
import AssistControl from "../pages/admin/assist_control/assist_control";

const routes = [
    {
        path: "/",
        name: "linea_tiempo",
        exact: true,
        icon: "bx bx-line-chart",
        component: TaskScheduledList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Línea de tiempo",
            permission_required: [
                "view_timeline",
            ],
        },
    },
    {
        path: "/",
        name: "inicio",
        exact: true,
        icon: "bx bxs-home",
        component: Init,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Inicio",
            permission_required: [
                "view_assistcontrol",
            ],
        },
    },
    {
        path: "/actividades",
        name: "actividades",
        exact: true,
        icon: "bx bx-stats",
        component: ActivitiesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Actividades",
            permission_required: [
                "view_activities",
            ],
        },
    },
    {
        path: "/actividades/detalle/:username/:date",
        name: "actividades",
        exact: true,
        icon: "bx bx-stats",
        component: DetailActivities,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Actividades",
            permission_required: [
                "view_activities",
            ],
        },
    },
    {
        path: "/actividades/mensuales/",
        name: "actividades",
        exact: true,
        icon: "bx bxs-bar-chart-alt-2",
        component: ActivitiesMonthly,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Actividades mensuales",
            permission_required: [
                "follow_up_assignments",
            ],
        },
    },

    {
        path: "/marcaciones/",
        name: "marcaciones",
        exact: true,
        icon: "bx bxs-chart",
        component: AssistControl,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Marcaciones",
            permission_required: [
                "follow_up_assignments",
            ],
        },
    },
    {
        path: "/actividades/agenda/:username/:usuario",
        name: "actividades",
        exact: true,
        icon: "bx bx-stats",
        component: ActivitiesAgenda,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Agenda de actividades",
            permission_required: [
                "view_activities",
            ],
        },
    },
    {
        path: "/archivos",
        name: "archivos",
        exact: true,
        icon: "bx bxs-file-find",
        component: TaskResources,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Consulta de archivos",
            permission_required: [
                "view_resources",
            ],
        },
    },
    {
        path: "/marcacion",
        name: "marcacion",
        exact: true,
        icon: "bx  bxs-alarm",
        component: Marking,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Marcación",
            permission_required: [
                "view_assistcontrol",
            ],
        },
    },
    {
        path: "/ingreso-tienda",
        name: "ingreso-tienda",
        exact: true,
        icon: "bx bx-log-in-circle",
        component: CheckIn,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Ingreso a tienda",
            permission_required: [
                "view_assistcontrol",
            ],
        },
    },
    {
        path: "/salida-tienda",
        name: "salida-tienda",
        exact: true,
        icon: "bx  bx-log-out-circle",
        component: CheckOut,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Salida de tienda",
            permission_required: [
                "view_assistcontrol",
            ],
        },
    },
    {
        path: "/tareas",
        name: "tareas",
        exact: true,
        icon: "bx bx-notepad",
        component: TaskScheduledListUser,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Agendamiento de tareas",
            permission_required: [
                "view_taskscheduled",
            ],
        },
    },
    /*{
        path: "/horarios",
        name: "horarios",
        exact: true,
        icon: "bx bxs-time-five",
        component: SchedulesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Horarios",
            permission_required: [
                "view_schedules",
            ],
        },
    },
    {
        path: "/horario/crear",
        name: "horarios",
        exact: false,
        icon: "bx bxs-time-five",
        component: CreateSchedules,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Horario",
            permission_required: [
                "add_schedules",
            ],
        },
    },
    {
        path: "/horario/editar/:id",
        name: "horarios",
        exact: true,
        icon: "bx bxs-time-five",
        component: UpdateSchedules,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Horario",
            permission_required: [
                "change_schedules",
            ],
        },
    }, */
    {
        path: "/lista-tareas",
        name: "lista_tarea",
        exact: true,
        icon: "bx bxs-file",
        component: TaskList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Lista de tareas",
            permission_required: [
                "view_tasklist",
            ],
        },
    },
    {
        path: "/lista-tarea/editar/:id",
        name: "lista-tareas",
        exact: false,
        icon: "bx bxs-note",
        component: UpdateTask,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Lista de tarea",
            permission_required: [
                "add_tasklist",
            ],
        },
    },
    {
        path: "/tipo-tareas",
        name: "tipo-tareas",
        exact: true,
        icon: "bx bxs-note",
        component: TaskTypesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Tipo de tareas",
            permission_required: [
                "view_types",
            ],
        },
    },
    {
        path: "/tipo-tarea/crear",
        name: "tipo-tareas",
        exact: false,
        icon: "bx bxs-note",
        component: CreateTaskTypes,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Tipo de tarea",
            permission_required: [
                "add_types",
            ],
        },
    },
    {
        path: "/tipo-tarea/editar/:id",
        name: "tipo-tareas",
        exact: true,
        icon: "bx bxs-note",
        component: UpdateTaskTypes,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Horario",
            permission_required: [
                "change_types",
            ],
        },
    },
    {
        path: "/observaciones",
        name: "observaciones",
        exact: true,
        component: Observations,
        icon: "bx bx-list-ul",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Observaciones",
            permission_required: [
                "view_observations",
            ],
        },
    },
    {
        path: "/detalles-omitir-tarea",
        name: "detalles-omitir-tarea",
        exact: true,
        component: SkipTask,
        icon: "bx bx-list-ul",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Detalles de omitir tarea",
            permission_required: [
                "view_skiptask",
            ],
        },
    },
    {
        path: "/provincias",
        name: "provincias",
        exact: true,
        icon: "bx bxs-map-alt",
        component: ProvincesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Provincias",
            permission_required: [
                "view_provinces",
            ],
        },
    },
    {
        path: "/provincia/crear",
        name: "provincias",
        exact: false,
        icon: "bx bxs-map-alt",
        component: CreateProvince,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Provincia",
            permission_required: [
                "add_provinces",
            ],
        },
    },
    {
        path: "/provincia/editar/:id",
        name: "provincias",
        exact: true,
        icon: "bx bxs-map-alt",
        component: UpdateProvince,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Provincia",
            permission_required: [
                "change_provinces",
            ],
        },
    },
    {
        path: "/ciudades",
        name: "ciudades",
        exact: true,
        icon: "bx bxs-city",
        component: CitiesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Ciudades",
            permission_required: [
                "view_cities",
            ],
        },
    },
    {
        path: "/ciudad/crear",
        name: "ciudades",
        exact: false,
        icon: "bx bxs-city",
        component: CreateCity,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Ciudad",
            permission_required: [
                "add_cities",
            ],
        },
    },
    {
        path: "/ciudad/editar/:id",
        name: "ciudades",
        exact: true,
        icon: "bx bxs-city",
        component: UpdateCity,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Ciudad",
            permission_required: [
                "change_cities",
            ],
        },
    },
    {
        path: "/zonas",
        name: "zonas",
        exact: true,
        icon: "bx bx-building",
        component: ZonesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "view_zones",
            ],
        },
    },
    {
        path: "/zona/crear",
        name: "zonas",
        exact: false,
        icon: "bx bx-building",
        component: CreateZone,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zona",
            permission_required: [
                "add_zones",
            ],
        },
    },
    {
        path: "/zona/editar/:id",
        name: "zonas",
        exact: true,
        icon: "bx bx-building",
        component: UpdateZone,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zona",
            permission_required: [
                "change_zones",
            ],
        },
    },
    {
        path: "/tiendas",
        name: "tiendas",
        exact: true,
        icon: "bx bxs-store",
        component: StoresList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Tiendas",
            permission_required: [
                "view_stores",
            ],
        },
    },
    {
        path: "/tienda/crear",
        name: "tiendas",
        exact: false,
        icon: "bx bxs-store",
        component: CreateStore,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Tienda",
            permission_required: [
                "add_stores",
            ],
        },
    },
    {
        path: "/tienda/editar/:id",
        name: "tiendas",
        exact: true,
        icon: "bx bxs-store",
        component: UpdateStore,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Tienda",
            permission_required: [
                "change_stores",
            ],
        },
    }, 
    {
        path: "/roles",
        name: "roles",
        icon: "bx bxs-user-pin",
        exact: true,
        component: GroupList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "view_group",
            ],
        },
    },
    {
        path: "/roles/crear",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: CreateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "add_group",
            ],
        },
    },
    {
        path: "/roles/editar/:id",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "change_group",
            ],
        },
    },
    {
        path: "/usuarios",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: Users,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "view_user",
            ],
        },
    },
    {
        path: "/usuarios/crear",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: CreateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "add_user",
            ],
        },
    },
    {
        path: "/usuarios/editar/:id",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "change_user",
            ],
        },
    },
    {
        path: "/notificaciones",
        name: "notificaciones",
        icon: "bx bxs-user",
        exact: true,
        component: NotificationsList,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Notificaciones",
            permission_required: [
                "view_notifications",
            ],
        },
    },
];

export default routes;
