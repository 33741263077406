import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
// import {useHistory} from "react-router-dom";
import Footer from "../../../components/footer";
import CardItem from "../../../components/card-item";
import Breadcrumbs from "../../../components/breadcrumbs";
import {URLAPI} from "../../../config";
import SelectInput from "../../../components/select";
import {searchActivitiesDetail, searchActivitiesMonthly, getLogHistoryTaskScheduled} from "../../../redux/task_scheduled";
import {DirectionsRenderer, GoogleMap, MarkerF, useJsApiLoader} from "@react-google-maps/api";
import {KEY_MAPS} from "../../../config";
import Modal from "../../../components/modal";
import LoadingSpinner from "../../../components/loading-spinner";
import {ToastContainer, toast} from "react-toastify";

function data_years() {
    const year = new Date().getFullYear();
    let data = [] 
    for (var i = year - 4; i <= year; i++) {
        data.push({'id': i, 'name': i});
    }
    return data;
}

const ActivitiesMonthly = (props) => {
    // let history = useHistory();
    //const {username, date} = useParams();
    const {token, user} = useSelector((state) => state.users_module.login.data) || null;

    const [data, setData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [username, setUsername] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [typeTask, setTypeTask] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    
    const years = data_years();

    const [item, setItem] = useState({});

    const _get_all_users = useSelector(
        (state) => state.users_module._get_all_users
    );

    const _get_user_data = useSelector(
        (state) => state.users_module._session_data
    );

    useEffect(() => {
        if (_get_user_data.data) {
            setTypeTask(_get_user_data.data.tipo_tarea);
        }
        setIsLoading(false);
    }, [_get_user_data, token, user]);

    useEffect(() => {
        if (_get_all_users.data) {
            let filter = _get_all_users.data.filter(x => x.activo === true && x.tipo_tarea.nombre === typeTask);
            setUsers(filter);
        }
    }, [_get_all_users, typeTask]);

    /* const _get_all_activities = useSelector(
        (state) => state.task_scheduled_module._get_all_activities
    );
 */
    /* useEffect(() => {
        if (_get_all_activities.data.datos) {
            let filtro = _get_all_activities.data.datos.filter(element => {
                if (element.username === username) {
                    return element;
                }
            })
            setData(filtro[0]);
            const year = new Date().getFullYear();
            let month = new Date().getMonth() + 1;
            if (month === 1) {
                month = 12
            } else {
                if (month.toString().length === 1) {
                    month = '0' + month;
                }
            }
            var day = (new Date(year, month, 0)).getDate();
            const date_start = year.toString() + '-' + month.toString() + '-01';
            const date_end = year.toString() + '-' + month.toString() + '-' + day.toString(); */
            /* let params = "?usuario=" + username + '&&fecha_inicio=' + date_start + '&&fecha_fin=' + date_end;
            activitiesDetail(params); */
            /* let variants = "?usuario=" + username + '&&fecha_inicio=' + date_start + '&&fecha_fin=' + date_end;
            document.getElementById("filtro").selectedIndex = parseInt(month);
            activitiesMonthly(variants);
        } 
        const year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        if (month === 1) {
            month = 12
        } else {
            if (month.toString().length === 1) {
                month = '0' + month;
            }
        }
        var day = (new Date(year, month, 0)).getDate();
        const date_start = year.toString() + '-' + month.toString() + '-01';
        const date_end = year.toString() + '-' + month.toString() + '-' + day.toString();
        let variants = "?usuario=" + username + '&&fecha_inicio=' + date_start + '&&fecha_fin=' + date_end;
        //document.getElementById("filtro").selectedIndex = parseInt(month);
        activitiesMonthly(variants);

        // eslint-disable-next-line
    }, [username]); */

    function search_activities_monthly() {
        setIsLoading(true);
        if (username === "") {
            toast.error("Debe seleccionar un usuario", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (month === 0) {
            toast.error("Debe seleccionar un mes", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (year === 0) {
            toast.error("Debe seleccionar un año", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        let v_month = month.toString()
        if (v_month.length === 1) {
            v_month = '0' + v_month;
        }
        var day = (new Date(year, v_month, 0)).getDate();
        const date_start = year.toString() + '-' + month.toString() + '-01';
        const date_end = year.toString() + '-' + month.toString() + '-' + day.toString();
        let variants = "?usuario=" + username + '&&fecha_inicio=' + date_start + '&&fecha_fin=' + date_end;
        activitiesMonthly(variants);
    }


    const activitiesMonthly = async (params) => {
        const response = await searchActivitiesMonthly(token, params);
        if (response && response.data) {
            let values = [];
            let perc = 0;
            let cont = 0;
            for (const [key, value] of Object.entries(response.data.datos)) {
                let porcentaje = 0;
                // eslint-disable-next-line
                let realizado = value.filter(element => {
                    if (parseInt(element.done) === 1) {
                        return element;
                    }
                })
                if (realizado.length > 0){
                    // eslint-disable-next-line
                    let omitido = value.filter(element => {
                        if (element.estado === 'OMITIDO') {
                            return element;
                        }
                    })
                    let c = value.length - omitido.length;
                    porcentaje = (parseFloat(realizado.length * 100) / parseFloat(c));
                }
                values.push({'tarea': key, 'porcentaje': porcentaje.toFixed(2), 'valores': value});
                cont += 1;
                perc += parseFloat(porcentaje);
            }
            setDatas(values);
            const porce = (perc / cont).toFixed(2);
            setPercentage(porce);
            setIsLoading(false);

        } else {
            setDatas([]);
            setIsLoading(false);
        }
    };

    const filter_month = [
        {name: "Enero", id: 1},
        {name: "Febrero", id: 2},
        {name: "Marzo", id: 3},
        {name: "Abril", id: 4},
        {name: "Mayo", id: 5},
        {name: "Junio", id: 6},
        {name: "Julio", id: 7},
        {name: "Agosto", id: 8},
        {name: "Septiembre", id: 9},
        {name: "Octubre", id: 10},
        {name: "Noviembre", id: 11},
        {name: "Diciembre", id: 12}
    ];

    const changeUsername = (e) => {
        setUsername(e.target.value);
        let filter = users.filter(x => x.usuario === e.target.value);
        if (filter.length > 0) {
            setData(filter[0]);
        }
    }

    const filterMonth = (e) => {
        setMonth(e.target.value);
    }

    const filterYear = (e) => {
        setYear(e.target.value);
    }

    // const filterToday = (e) => {
    //     const year = new Date().getFullYear();
    //     let month = e.target.value.toString();
    //     if (month.length === 1) {
    //         month = '0' + month;
    //     }
    //     var day = (new Date(year, month, 0)).getDate();
    //     const date_start = year.toString() + '-' + month.toString() + '-01'
    //     const date_end = year.toString() + '-' + month.toString() + '-' + day.toString();
    //     /* let params = "?usuario=" + username + '&&fecha_inicio=' + date_start + '&&fecha_fin=' + date_end;
    //     activitiesDetail(params); */
    //     let variants = "?usuario=" + username + '&&fecha_inicio=' + date_start + '&&fecha_fin=' + date_end;
    //     activitiesMonthly(variants);
    // }

    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: KEY_MAPS,
    });

    const [modal, setModal] = useState(false);
    const [modalLocations, setModalLocations] = useState(false);

    const [directionsResponse, setDirectionsResponse] = useState(null);

    function clearRoute() {
        setDirectionsResponse(null);
    }

    let directionsService;
    const calculateRoute = () => {
        directionsService = new window.google.maps.DirectionsService()
        const routesCopy = item.ubicaciones.map(route => {
            return {
                location: {lat: route.lat, lng: route.lng},
                stopover: true
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.pop().location;
        const waypoints = routesCopy;

        //call getDirection function
        getDirection(origin, destination, waypoints);
    };

    const getDirection = (origin, destination, waypoints) => {
        waypoints.length >= 1
            ? directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.log('error fetching directions1455 ' + result);
                    }
                }
            )
            : directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.log('error fetching directions ' + result);
                    }
                }
            );
    };

    const [dataHistory, setDataHistory] = useState([]);

    const activitiesDetail = async (params) => {
        const response = await searchActivitiesDetail(token, params);
        if (response && response.data && response.data.cantidad > 0) {
            setItem(response.data.datos[0]);
            let result = "?id_tarea_programada=" + response.data.datos[0].id_tarea_horario;
            const respo = await getLogHistoryTaskScheduled(token, result);
            if (respo && respo.data && respo.data.cantidad > 0) {
                setDataHistory(respo.data.datos);
            }
            setModal(true);
            setIsLoading(false);
        } else {
            setItem({});
            setIsLoading(false);
        }
    };

    const onItemClickListener = async (id) => {
        setIsLoading(true);
        let params = "?id_tarea_horario=" + id;
        activitiesDetail(params);
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div><ToastContainer/></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Actividades mensuales"
                            items={[{label: "Lista de actividades", link: "/actividades/mensuales"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="border_card d-flex">
                                            <p className="ml-1" style={{marginTop: 10, fontSize: 18, color: '#727E8C'}}>Actividades de mensuales</p>
                                        </div>
                                        <CardItem>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6 col-12 mt-md-1">
                                                    <label>Usuario</label>
                                                    <SelectInput
                                                        id="username"
                                                        name="username"
                                                        value={username}
                                                        onChange={changeUsername}
                                                        options={users ? users.map((item) => ({
                                                            name: item.nombres + ' ' + item.apellidos,
                                                            id: item.usuario,
                                                        })) : []}
                                                    />
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mt-md-1">
                                                    <label>Mes</label>
                                                    <SelectInput
                                                        id="month"
                                                        name="filtro"
                                                        options={filter_month}
                                                        onChange={filterMonth}
                                                        select="HOY"
                                                    />
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mt-md-1">
                                                    <label>Año</label>
                                                    <SelectInput
                                                        id="year"
                                                        name="year"
                                                        options={years}
                                                        onChange={filterYear}
                                                        select={year}
                                                    />
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mt-md-3 mt-sm-2 text-center">
                                                    <button type='button' onClick={() => {search_activities_monthly()}} className='btn btn-calendar'>Buscar</button>
                                                </div>
                                                <div className="col-12 mt-2">
                                                    <div className="d-flex">
                                                        <div className="list-icon mr-1">
                                                            <div
                                                                className="avatar bg-rgba-primary m-0">
                                                                <img
                                                                    src={data.foto ? URLAPI + '/media/' + data.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                                    }
                                                                    alt="img"
                                                                    height="70"
                                                                    width="70"/>
                                                            </div>
                                                        </div>
                                                        <div className="list-content" style={{width: '50%'}}>
                                                            <p className="text-bold-700"
                                                               style={{fontSize: 18}}>{data.usuario}</p>
                                                            <p className="text-bold-700" style={{
                                                                color: '#FFC912',
                                                                fontSize: 40
                                                            }}>{parseFloat(percentage).toFixed(2)} %</p>
                                                            <div className="progress mt-1">
                                                                <div className="progress-bar bg-calendar"
                                                                     role="progressbar"
                                                                     style={{width: percentage + '%'}}
                                                                     aria-valuenow="100" aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardItem>
                                    </div>
                                </div>
                                <div className="row">
                                    {datas && 
                                        datas.map((x) => (
                                            <div className="col-12">
                                                <h5>{x.tarea}</h5>
                                                <table className="table table-bordered">
                                                    <tr><th colspan="4">Porcentaje de cumplimineto: {x.porcentaje} %</th></tr>
                                                    {x.valores.map((y) => (
                                                        <tr>
                                                            <td width={'23%'}><small>Tienda</small><br/><strong>{y.tienda}</strong></td>
                                                            <td width={'23%'}><small>Fecha</small><br/><strong>{y.fecha}</strong></td>
                                                            <td width={'23%'}><small>Estado</small><br/><strong>{y.estado}</strong></td>
                                                            <th width={'31%'} className="text-center">
                                                                <br/>
                                                                {y.estado && 
                                                                    <p onClick={() => {onItemClickListener(y.id)}} style={{cursor: 'pointer'}}>Ver detalle</p>
                                                                }
                                                            </th>
                                                        </tr>
                                                            
                                                    ))}
                                                </table>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                    <div className="modal-header">
                        <h5 className="text-bold-400">{(item && item.tipo !== 'TAREA') &&
                            <>{item.descripcion}</>
                        }</h5>
                        <button type="button" style={{marginRight: -24}}
                                className="btn btn-calendar badge-circle" onClick={() => {
                            setModal(!modal);
                            setItem({})
                            setDataHistory([]);
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row mb-1">
                            {(item && item.tipo === 'TAREA') ?
                                <>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>{item.tarea}</strong>
                                        </div>
                                        <span>{item.lista_tarea}</span><br/>
                                        <span>{(item.realizado === 1) ? 'Realizada el ' + item.fecha : 'Fecha: ' + item.fecha}</span>
                                        {(item.ubicaciones.length > 0) &&
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href="#" onClick={() => {
                                                    setModalLocations(true);
                                                    calculateRoute();
                                                }}>Ver ubicación</a>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>{item.tienda}</strong>
                                        </div>
                                        <span>{item.observacion_inicial}</span>
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Recursos de la tarea</strong>
                                        </div>
                                        {item.recursos.map((re) => (
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Resultados</strong>
                                        </div>
                                        {item.resultados.map((re) => (
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Fotografías de evidencia</strong>
                                        </div>
                                        <div className="row mb-1">
                                            {item.imagenes.map((re) => (
                                                <div className="col-4 text-center">
                                                    {/*eslint-disable-next-line*/}
                                                    <a href={URLAPI + re.archivo} target="_blank">
                                                        <img
                                                            src={URLAPI + re.archivo}
                                                            alt="" height="80" width="100"/>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Observaciones finales</strong>
                                        </div>
                                        {item.observacion && 
                                            <div style={{marginBottom: 10}}>
                                                <span>{item.observacion}</span><br/>
                                            </div>
                                        }
                                        <div style={{marginBottom: 10}}>
                                            <span>{item.observacion_final}</span><br/>
                                        </div>
                                    </div>
                                    {(dataHistory.length > 0) && (
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Historias</strong>
                                            </div>
                                            <div className="mb-1">
                                                    {dataHistory.map((item) => (
                                                        item.logs.map((x) => (
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div><span>{x.nombre_tipo}</span></div>
                                                                <div className="text-right"><span className='text-right'>{x.fecha}</span></div>
                                                            </div>
                                                        ))
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                                :
                                <>
                                    <div className="col-12 mb-1">
                                        <span>Fecha y hora: {item.fecha}</span>
                                    </div>
                                    <div className="col-12 mb-1">
                                        <div className="d-flex">
                                            <img
                                                src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                alt="" height="20" width="20"
                                                className="rounded-circle mr-1"/>
                                            <span>Ubicación</span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1 text-center">
                                        {(item && item.latitud && item.longitud && isLoaded) &&
                                            <GoogleMap
                                                center={{
                                                    lat: parseFloat(item.latitud),
                                                    lng: parseFloat(item.longitud)
                                                }}
                                                zoom={11}
                                                options={{streetViewControl: false}}
                                                mapContainerStyle={{width: "100%", height: "50vh"}}
                                            >
                                                <MarkerF
                                                    position={{
                                                        lat: parseFloat(item.latitud),
                                                        lng: parseFloat(item.longitud)
                                                    }}
                                                />
                                            </GoogleMap>
                                        }
                                    </div>
                                    <div className="col-12 mb-1">
                                        <div className="d-flex">
                                            <img
                                                src={window.location.origin + "/base/app-assets/images/logo/image.png"}
                                                alt="" height="20" width="20"
                                                className="rounded-circle mr-1"/>
                                            <span>Foto</span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1 text-center">
                                        {/*eslint-disable-next-line*/}
                                        <a href={URLAPI + item.imagen} target="_blank">
                                            <img
                                                src={item.imagen ? URLAPI + item.imagen : window.location.origin + "/base/app-assets/images/logo/image.png"}
                                                alt="" height="180" width="50%"/>
                                        </a>
                                    </div>
                                </>

                            }
                        </div>
                    </div>
                </Modal>

                <Modal className="modal-main" show={modalLocations} style={{width: "40%"}}>
                    <div>
                        <button type="button" className="close btn btn-calendar badge-circle" onClick={() => {
                            setModalLocations(!modalLocations);
                            clearRoute();
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row mb-1">
                            {(isLoaded && item && item.ubicaciones) &&
                                <GoogleMap
                                    center={item.ubicaciones[0]}
                                    zoom={11}
                                    options={{streetViewControl: false}}
                                    mapContainerStyle={{width: "100%", height: "50vh"}}
                                >
                                    {/*{item.ubicaciones.map((co) => (*/}
                                    {/*    <MarkerF*/}
                                    {/*        position={co}*/}
                                    {/*    />*/}
                                    {/*))}*/}
                                    {directionsResponse && (
                                        <DirectionsRenderer directions={directionsResponse}/>
                                    )}
                                </GoogleMap>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
            <Footer/>
        </div>
    )
}

export default ActivitiesMonthly;
