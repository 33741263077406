import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    createStore,
    CREATE_STORE,
    clear_stores,
    getAllStores
} from "../../../redux/stores";
import SelectInput from "../../../components/select";
import Footer from "../../../components/footer";

const CreateStore = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const _get_all_cities = useSelector(
        (state) => state.cities_module._get_all_cities
    );

    const _get_all_zones = useSelector(
        (state) => state.zones_module._get_all_zones
    );

    const [cities, setCities] = useState([]);
    const [zones, setZones] = useState([]);

    useEffect(() => {
        if (_get_all_cities.data.datos) {
            setCities(_get_all_cities.data.datos);
        }
    }, [_get_all_cities]);

    useEffect(() => {
        if (_get_all_zones.data.datos) {
            setZones(_get_all_zones.data.datos);
        }
    }, [_get_all_zones]);

    const _create_store = useSelector(
        (state) => state.stores_module._create_store
    );
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        codigo: "",
        nombre: "",
        id_ciudad: 0,
        id_zona: 0,
    });

    useEffect(() => {
        if (_create_store.status === 201) {
            dispatch(clear_stores(CREATE_STORE));
            history.push("/tiendas");
            dispatch(getAllStores(token));
        } else if (_create_store.status !== 0) {
            dispatch(clear_stores(CREATE_STORE));
            toast.error(_create_store.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_create_store, dispatch, history, token]);


    const handleChange = (e) => {
        if (e.target.name === "id_ciudad" && e.target.name === "id_zona") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.codigo === "") {
            toast.error("Debe ingresar el código de la tienda", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (form.id_ciudad === 0) {
            toast.error("Debe seleccionar una ciudad", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (form.id_zona === 0) {
            toast.error("Debe seleccionar una zona", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(createStore(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Tienda"
                            items={[
                                {label: "Lista de tiendas", link: "/tiendas"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Tienda</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className=" col-md-6">
                                            <Input
                                                name="codigo"
                                                label="Código"
                                                value={form.codigo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className=" col-md-6">
                                            <Input
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Ciudad</label>
                                                <SelectInput
                                                    label="ciudad"
                                                    name="id_ciudad"
                                                    value={form.id_ciudad}
                                                    onChange={handleChange}
                                                    options={cities ? cities.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Zona</label>
                                                <SelectInput
                                                    label="zona"
                                                    name="id_zona"
                                                    value={form.id_zona}
                                                    onChange={handleChange}
                                                    options={zones ? zones.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/tiendas" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Crear" theme="calendar" classes="m-1"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CreateStore;
