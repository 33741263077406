import {
  URLAPI,
  TOKEN_PATH,
  USERS_PATH,
  USERS_CREATE_PATH,
  USERS_UPDATE_PATH,
  USERS_UPDATE_PASSWORD_PATH,
  USER_GET_PATH,
  USER_PASSWORD_RESET_PATH,
  USER_GET_ALL_PERMISSIONS_PATH,
  USER_GROUPS_PATH,
  USER_CREATE_GROUP_PATH,
  USER_UPDATE_GROUP_PATH,
  USER_ACTIVATE_PATH,
  USER_LOGOUT_PATH,
  USER_PASSWORD_RESET_CONFIRM_PATH,
  USER_TYPE_PATH,
} from "../config/index";
import axios from "axios";
import { Buffer } from 'buffer';

/** @module Redux/users */

/* Es el estado inicial del reductor. */
const init = {
  login: {
    data: {
      token: "",
      user: ''
    },
    status: 0,
    message: {},
    authenticated: false,
  },
  _session_data: {
    data: {},
    status: 0,
    message: {},
  },
  _get_all_users: {
    data: [],
    status: 0,
    message: {},
  },
  _create_user: {
    data: {},
    status: 0,
    message: {},
  },
  _update_user: {
    data: {},
    status: 0,
    message: {},
  },
  _update_password_user: {
    data: {},
    status: 0,
    message: {},
  },
  _get_all_groups: {
    data: [],
    status: 0,
    message: {},
  },
  _reset_password: {
    data: {},
    status: 0,
    message: {},
  },
  _reset_password_confirm: {
    data: {},
    status: 0,
    message: {},
  },
  _get_all_permissions: {
    data: [],
    status: 0,
    message: {},
  },
  _create_group: {
    data: {},
    status: 0,
    message: {},
  },
  _update_group: {
    data: {},
    status: 0,
    message: {},
  },
  _activate_account: {
    data: {},
    status: 0,
    message: {},
  },
};

/**
 * @constant
 * @type {string}
 *  Es una constante que se utiliza para identificar la acción que se va a despachar.
 */
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

/**
 * @constant
 * @type {string}
 * Es una constante que se utiliza para identificar la acción que se va a despachar.
 */
const GET_ALL_USERS = "GET_ALL_USERS";

/**
 * @constant
 * @type {string}
 * Una constante que se utiliza para identificar la acción que se va a enviar.
 * */
export const CREATE_USER = "CREATE_USER";

/**
 * @constant
 * @type {string}
 * Una constante que se utiliza para identificar la acción que se va a enviar. */
const GET_ALL_GROUP = "GET_ALL_GROUP";

/**
 * @constant
 * @type {string}
 *  Una constante que se utiliza para identificar la acción que se va a enviar. */
export const UPDATE_USER = "UPDATE_USER";


/**
 * @constant
 * @type {string}
 * Una constante que se utiliza para identificar la acción que se va a enviar. */
const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";


/**
 * @constant
 * @type {string}
 *  Una constante que se utiliza para identificar la acción que se va a enviar. */
const GET_SESSION_DATA = "GET_SESSION_DATA";


/**
 * @constant
 * @type {string}
 * Una constante que se utiliza para identificar la acción que se va a enviar. */
export const CREATE_GROUP = "CREATE_GROUP";


/**
 * @constant
 * @type {string}
 * Una constante que se utiliza para identificar la acción que se va a enviar. */
export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";


/**
 * @constant
 * @type {string}
 * Una constante que se utiliza para identificar la acción que se va a enviar. */
export const LOGOUT = "LOGOUT";


/**
 * @constant
 * @type {string}
 * Una constante que se utiliza para identificar la acción que se va a enviar. */
export const UPDATE_GROUP = "UPDATE_GROUP";



/**
 * @constant
 * @type {string}
 * Una constante que se utiliza para identificar la acción que se va a enviar. */
export const RESET_PASSWORD = "RESET_PASSWORD";


/**
 * @constant
 * @type {string}
 * Una constante que se utiliza para identificar la acción que se va a enviar. */
export const RESET_PASSWORD_CONFIRM = "RESET_PASSWORD_CONFIRM";

export const UPDATE_PASSWORD_USER = "UPDATE_PASSWORD_USER";

/**
 * @function users_module
 * @description Toma el estado actual y una acción, y devuelve el siguiente estado
 * @param [state] - Este es el estado actual de la tienda.
 * @param action - Esta es la acción que se envía desde el componente.
 * @returns El estado está siendo devuelto.
 */
export const users_module = (state = init, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: action.payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        _get_all_users: action.payload,
      };
    case CREATE_USER:
      return {
        ...state,
        _create_user: action.payload,
      };
    case GET_ALL_GROUP:
      return {
        ...state,
        _get_all_groups: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        _update_user: action.payload,
      };
    case UPDATE_PASSWORD_USER:
        return {
            ...state,
            _update_password_user: action.payload,
        };
    case GET_SESSION_DATA:
      return {
        ...state,
        _session_data: action.payload,
      };
    case GET_ALL_PERMISSIONS:
      return {
        ...state,
        _get_all_permissions: action.payload,
      };
    case CREATE_GROUP:
      return {
        ...state,
        _create_group: action.payload,
      };

    case UPDATE_GROUP:
      return {
        ...state,
        _update_group: action.payload,
      };
    case ACTIVATE_ACCOUNT:
      return {
        ...state,
        _activate_account: action.payload,
      };
    case LOGOUT:
      return init;

    case RESET_PASSWORD:
      return {
        ...state,
        _reset_password: action.payload,
      };
    case RESET_PASSWORD_CONFIRM:
      return {
        ...state,
        _reset_password_confirm: action.payload,
      };
    default:
      return state;
  }
};

/**
 * @function login
 * @description  Toma un objeto de datos como parámetro y devuelve una función que toma una función de despacho como
 * parámetro
 * @param data - Los datos que se enviarán como cuerpo de la solicitud.
 * @example
 * const data = {
 *  username: "admin",
 *  password: "admin"
 * }
 * dispatch(login(data))
 */
export const login = (data) => async (dispatch) => {
  try {
    const token =  Buffer.from(`${data.username}:${data.password}`).toString('base64');
    const response = await axios.post(`${URLAPI}${TOKEN_PATH}`, data);
    console.log(response);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        data: {
          token: token,
          user: data.username,
        },
        status: 200,
        message: "",
        authenticated: true,
      },
    });
    dispatch(get_user_session_data(
      token,
      data.username,
      data.password
    ));
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          data: {},
          status: error.response.status,
          message: "Usuario o contraseña incorrecta",
          authenticated: false,
        },
      });
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          data: {},
          status: 500,
          message: "Error al iniciar sesión",
          authenticated: false,
        },
      });
    }
  }
};

/**
 * @function logout
 * @description  Toma un objeto de datos como parámetro y devuelve una función que toma una función de despacho como
 * parámetro
 * @param token - El token que se enviará como cabecera de la solicitud.
 * @example
 * dispatch(logout(token))
 */
export const logout = (token) => async (dispatch) => {
  try {
    const response = await axios.post(`${URLAPI}${USER_LOGOUT_PATH}`, {}, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    console.log(response);
    dispatch({
      type: LOGOUT,
      payload: {
        data: {},
        status: 0,
        message: "",
        authenticated: false,
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: LOGOUT,
        payload: {
          data: {},
          status: error.response.status,
          message: "Error al cerrar sesión",
          authenticated: false,
        },
      });
    } else {
      dispatch({
        type: LOGOUT,
        payload: {
          data: {},
          status: 500,
          message: "Error al cerrar sesión",
          authenticated: false,
        },
      });
    }
  }
}


/**
 * @function reset_password
 * @description  Toma un objeto de datos como parámetro y devuelve una función que toma una función de despacho como
 * parámetro
 * @param data - Los datos que se enviarán como cuerpo de la solicitud.
 * @example
 * const data = {
 *    email: "correo@correo.com",
 *       url:window.location.origin + "/recuperar-contrasena",
 * }
 * dispatch(reset_password(data))
 */
export const reset_password = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${URLAPI}${USER_PASSWORD_RESET_PATH}`, data);
    dispatch({
      type: RESET_PASSWORD,
      payload: {
        data: {},
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: RESET_PASSWORD,
        payload: {
          data: {},
          status: error.response.status,
          message: "Error al recuperar contraseña",
        },
      });

    } else {
      dispatch({
        type: RESET_PASSWORD,
        payload: {
          data: {},
          status: 500,
          message: "Error al recuperar contraseña",
        },
      });
    }
  }
}

/**
 * @function reset_password_confirm
 * @description  Toma un objeto de datos como parámetro y devuelve una función que toma una función de despacho como
 * parámetro
 * @param data - Los datos que se enviarán como cuerpo de la solicitud.
 * @example
 * const data = {
 *   new_password1:'12345678',
 *  new_password2:'12345678',
 * uid:'12345678',
 * token:'12345678',
 * }
 * dispatch(reset_password_confirm(data))
 */
export const reset_password_confirm = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${URLAPI}${USER_PASSWORD_RESET_CONFIRM_PATH}`, data);
    dispatch({
      type: RESET_PASSWORD_CONFIRM,
      payload: {
        data: {},
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: RESET_PASSWORD_CONFIRM,
        payload: {
          data: {},
          status: error.response.status,
          message: "Error al confirmar contraseña",
        },
      });
    } else {
      dispatch({
        type: RESET_PASSWORD_CONFIRM,
        payload: {
          data: {},
          status: 500,
          message: "Error al confirmar contraseña",
        },

      });
    }
  }
}


/**
 * @function get_all_users
 * @description Es una función que devuelve una función que devuelve un objeto.
 * @param token - El token que obtiene de la acción de inicio de sesión.
 * @example
 * dispatch(get_all_users(token))
 */
export const get_all_users = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${USERS_PATH}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_USERS,
      payload: {
        data: response.data.datos,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ALL_USERS,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.message,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_USERS,
        payload: {
          data: [],
          status: 500,
          message: "Error al obtener los usuarios",
        },
      });
    }
  }
};


/**
 * @function get_user_session_data
 * @description Realiza una solicitud a la API y, si la solicitud se realiza con éxito, se devuelve un objeto con los datos de la sesión.
 * @param token - El token que obtiene de la acción de inicio de sesión.
 * @param usuario - El usuario que obtiene de la acción de inicio de sesión.
 * @param password - La contraseña que obtiene de la acción de inicio de sesión.
 * @example
 * dispatch(get_user_session_data(token, usuario, password))
 */
export const get_user_session_data = (token, usuario, password) => async (
  dispatch
) => {
  try {
    const response = await axios.get(`${URLAPI}${USER_GET_PATH}?usuario=${usuario}&password=${password}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_SESSION_DATA,
      payload: {
        data: response.data.datos,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_SESSION_DATA,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.message,
        },
      });
    } else {
      dispatch({
        type: GET_SESSION_DATA,
        payload: {
          data: [],
          status: 500,
          message: "Error al obtener los usuarios",
        },
      });
    }
  }
}



/**
 * @function create_user
 * @description Realiza una solicitud POST a la API y, si tiene éxito, envía una acción con los datos de respuesta;
 * de lo contrario, envía una acción con el mensaje de error.
 * @param data - Los datos que se enviarán al servidor.
 * @param token - El token que obtiene de la acción de inicio de sesión.
 * @example
 * const data = {
        usuario: "usuario",
        nombres: "nombres",
        apellidos: "apellidos",
        correo: "correo",
        id_empresa: 1,
        id_grupo_permiso: 1
        centros_costos": [
                          {
                            codigo: "código",
                            nombre: "nombre"
                          }
                        ]
    }
    dispatch(create_user(data, token))
 */
export const create_user = (data, token) => async (dispatch) => {
  try {
    let form = new FormData();
    for (var key in data) {
      if (key !== 'tiendas') {
        form.append(key, data[key]);
      } else {
        form.append(key, JSON.stringify(data[key]));
      }
    }

    const response = await axios.post(`${URLAPI}${USERS_CREATE_PATH}`, form, {
      headers: {
        Authorization: `Basic ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({
      type: CREATE_USER,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_USER,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_USER,
        payload: {
          data: {},
          status: 500,
          message: "Error al crear el usuario",
        },
      });
    }
  }
};

/**
 * @function update_user
 * @description Hace una solicitud a la API para actualizar un usuario y luego envía una acción con la respuesta
 * @param data - Los datos que se enviarán a la API.
 * @param id - El id del usuario a actualizar.
 * @param token - El token que obtiene de la acción de inicio de sesión.
 * @example
 * const data = {
 * usuario: "usuario",
 * nombres: "nombres",
 * apellidos: "apellidos",
 * correo: "
 * id_empresa: 1,
 * id_grupo_permiso: 1
 * centros_costos": [
 *                 {
 *                  codigo: "código",
 *                 nombre: "nombre"
 *                }
 *             ]
 * }
 * dispatch(update_user(data, id, token))
 */
export const update_user = (data, id, token) => async (dispatch) => {
  try {
    let form = new FormData();
    for (var key in data) {
      if (key !== 'tiendas') {
        form.append(key, data[key]);
      } else {
        form.append(key, JSON.stringify(data[key]));
      }
    }

    const response = await axios.patch(
      `${URLAPI}${USERS_UPDATE_PATH}${id}/`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    dispatch({
      type: UPDATE_USER,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_USER,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_USER,
        payload: {
          data: {},
          status: 500,
          message: "Error al actualizar el usuario",
        },
      });
    }
  }
};

export const update_password_user = (token, id, data) => async (dispatch) => {
  try {
      const response = await axios.patch(`${URLAPI}${USERS_UPDATE_PASSWORD_PATH}${id}/`, data, {
          headers: {
            Authorization: `Basic ${token}`,
          },
      });
      dispatch({
          type: UPDATE_PASSWORD_USER,
          payload: {
              data: {},
              status: response.status,
              message: "",
          },
      });
  } catch (error) {
      if (error.response != null) {
          dispatch({
              type: UPDATE_PASSWORD_USER,
              payload: {
                  data: {},
                  status: error.response.status,
                  message: error.response.data.mensaje,
              },
          });
      } else {
          dispatch({
              type: UPDATE_PASSWORD_USER,
              payload: {
                  data: {},
                  status: 500,
                  message: "Error al actualizar su contraseña",
              },

          });
      }
  }
}

/**
 * @function get_all_groups
 * @description Hace una solicitud a la API para obtener todos los grupos y luego envía una acción con la respuesta.
 * @param token - El token del usuario que ha iniciado sesión.
 * @example
 * dispatch(get_all_groups(token))
 */
export const get_all_groups = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${USER_GROUPS_PATH}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_GROUP,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ALL_GROUP,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_GROUP,
        payload: {
          data: [],
          status: 500,
          message: "Error al obtener los grupos",
        },
      });
    }
  }
};


/**
 * @function get_all_permisions
 * @description Hace una solicitud a la API para obtener todos los permisos y luego envía una acción con la respuesta.
 * @param token - El token del usuario que ha iniciado sesión.
 * @example
 * dispatch(get_all_permisions(token))
 */
export const get_all_permisions = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${USER_GET_ALL_PERMISSIONS_PATH}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_PERMISSIONS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ALL_PERMISSIONS,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_PERMISSIONS,
        payload: {
          data: [],
          status: 500,
          message: "Error al obtener los permisos",
        },
      });
    }
  }
}


/**
 * @function create_group
 * @description Hace una solicitud a la API para crear un grupo y luego envía una acción con la respuesta.
 * @param data - Los datos que se enviarán a la API.
 * @param token - El token del usuario que ha iniciado sesión.
 * @example
 * const data = {
 * nombre: "nombre",
 * permisos: [
 *            {
 *              codigo: "add_user",
 *            }
 *            ]
 * }
 * dispatch(create_group(data, token))
 */
export const create_group = (data, token) => async (dispatch) => {
  try {
    const response = await axios.post(`${URLAPI}${USER_CREATE_GROUP_PATH}`, data, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: CREATE_GROUP,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_GROUP,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_GROUP,
        payload: {
          data: {},
          status: 500,
          message: "Error al crear el grupo",
        },
      });
    }
  }
}

/**
 * @function update_group
 * @description Hace una solicitud a la API para actualizar un grupo y luego envía una acción con la respuesta.
 * @param data - Los datos que se enviarán a la API.
 * @param id - El id del grupo que se actualizará.
 * @param token - El token del usuario que ha iniciado sesión.
 * @example
 * const data = {
 * nombre: "nombre",
 * permisos: [
 *           {
 *            codigo: "add_user",
 *          }
 *         ]
 * }
 * dispatch(update_group(data, id, token))
 *
 */
export const update_group = (data, id, token) => async (dispatch) => {
  try {
    const response = await axios.patch(`${URLAPI}${USER_UPDATE_GROUP_PATH}${id}/`, data, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: UPDATE_GROUP,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_GROUP,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_GROUP,
        payload: {
          data: {},
          status: 500,
          message: "Error al actualizar el grupo",
        },
      });
    }
  }
}


/**
 * @function activate_account
 * @description Hace una solicitud a la API para activar una cuenta y luego envía una acción con la respuesta.
 * @param id - El id del usuario que se desea activar.
 * @param token - El token del usuario que ha iniciado sesión.
 * @example
 * dispatch(activate_account(id, token))
 */
export const activate_account = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${URLAPI}${USER_ACTIVATE_PATH}`,data);
    dispatch({
      type: ACTIVATE_ACCOUNT,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ACTIVATE_ACCOUNT,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: ACTIVATE_ACCOUNT,
        payload: {
          data: {},
          status: 500,
          message: "Error al activar la cuenta",
        },
      });
    }
  }
}

export const searchTypeUser = async (token, paramsUrl) => {
  return await axios.get(`${URLAPI}${USER_TYPE_PATH}${paramsUrl}`, {
      headers: {
          Authorization: `Basic ${token}`,
      },
  });
}

export const clear_user_module = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: {},
      status: 0,
      message: "",
    },
  });
}
